import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo772.png";

const settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplaySpeed: 0,
  autoplay: true,
  cssEase: "linear",
  draggable: false,
  touchMove: false,
  swipe: false,
  pauseOnFocus: false,
  pauseOnHover: false,
};
const lgSetting = Object.assign({}, settings);
const mdSetting = Object.assign({}, settings);
mdSetting["slidesToShow"] = 5;
const smSetting = Object.assign({}, settings);
smSetting["slidesToShow"] = 3;

const categories = [
  { path: "/food", title: "健康な食生活" },
  { path: "/livingware", title: "安心な生活用品" },
  { path: "/cosmetic", title: "安心な化粧品" },
  { path: "/immunity", title: "免疫アップに" },
  { path: "/body", title: "体のケア・学び" },
];

const personImageNum = 12;

function Home() {
  return (
    <div>
      <div className="flex justify-between mb-5 flex-col md:flex-row">
        <div className="flex-1"></div>
        <div className="flex-1">
          <img className="main-icon" src={logo} />
        </div>
        <div className="flex flex-1 items-center justify-center">
          <a
            href="https://fudanbi.stores.jp/"
            target="__blank"
            className="
            p-3
            text-white
            bg-blue-700
            hover:bg-blue-500
            focus:outline-none
            focus:ring-2
            focus:ring-inset
            focus:ring-indigo-500
            "
          >
            <svg
              className="icon mb-1 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            ふだんびショップ
          </a>
        </div>
      </div>

      <div className="hidden lg:block">
        <Slider {...lgSetting}>
          {(() => {
            let images = [...Array(personImageNum)].map((_, i) => {
              let num = i + 1;
              return (
                <div key={`main-slider-${num}`}>
                  <img
                    className="h-56 w-full object-cover"
                    src={
                      require(`../assets/images/producer/${num}.jpg`).default
                    }
                  />
                </div>
              );
            });
            return images;
          })()}
        </Slider>
      </div>
      <div className="hidden lg:hidden md:block">
        <Slider {...mdSetting}>
          {(() => {
            let images = [...Array(personImageNum)].map((_, i) => {
              let num = i + 1;
              return (
                <div key={`main-slider-${num}`}>
                  <img
                    className="h-56 w-full object-cover"
                    src={
                      require(`../assets/images/producer/${num}.jpg`).default
                    }
                  />
                </div>
              );
            });
            return images;
          })()}
        </Slider>
      </div>

      <div className="md:hidden">
        <Slider {...smSetting}>
          {(() => {
            let images = [...Array(personImageNum)].map((_, i) => {
              let num = i + 1;
              return (
                <div key={`main-slider-${num}`}>
                  <img
                    className="h-56 w-full object-cover"
                    src={
                      require(`../assets/images/producer/${num}.jpg`).default
                    }
                  />
                </div>
              );
            });
            return images;
          })()}
        </Slider>
      </div>
      <div className="Home-contents flex flex-col md:flex-row  mt-3 mx-auto px-5">
        <div className="Home-message">
          <p className="p-5">
            健康で安心な普段の生活のために貴方にとっての『特別』をお届けします。
            <br />
            <br />
            便利さ、効率性を求め続ける現代社会。それに伴って日々の健康や安心を阻害する実にさまざまな
            物質や環境被害が生まれています。
            <br />
            <br />
            食品でも生活用品でも化粧品でも一つ一つに含まれるリスク要因
            は僅かでも毎日の蓄積で知らないうちに心身の健康が失われていく。
            <br />
            <br />
            普段の生活にできる限り安心で 健康的なモノを取り入れること。
            <br />
            <br />
            それこそが『普段美』が信じる美しい生き方です。 <br />
            <br />
            それに相応しい活動を続けている生産者・供給者の考え方・生き方を知っていただき、共感する
            生活者が応援する。
            <br />
            <br />
            そんな社会を一緒に作り上げていきましょう。
          </p>
        </div>
        <div className="Home-categories flex flex-col hidden md:block">
          {(() => {
            return categories.map((category, i) => {
              let position = "flex flex-row";
              if (i % 2 === 0) {
                position += " justify-start";
              } else {
                position += " justify-end";
              }
              return (
                <div className={position} key={`category${i}`}>
                  <div className="Home-category-wrap">
                    <Link to={category.path}>
                      <div
                        className="p-6
                          text-white
                          bg-blue-700
                          hover:bg-blue-500
                          focus:outline-none
                          focus:ring-2
                          focus:ring-inset
                          focus:ring-indigo-500
                          mb-5
                          Home-category
                          text-center
                      "
                      >
                        {category.title}
                      </div>
                    </Link>
                  </div>
                </div>
              );
            });
          })()}
        </div>
        <div className="flex flex-col md:hidden mb-5">
          {(() => {
            return categories.map((category, i) => {
              let position = "flex flex-row";
              if (i % 2 === 0) {
                position += " justify-start";
              } else {
                position += " justify-end";
              }
              return (
                <div className={position} key={`category${i}`}>
                  <div>
                    <Link to={category.path}>
                      <div
                        className="py-12
                          px-12
                          text-white
                          bg-blue-700
                          hover:bg-blue-500
                          focus:outline-none
                          focus:ring-2
                          focus:ring-inset
                          focus:ring-indigo-500
                          Home-category
                          text-center
                          -mb-7
                      "
                      >
                        {category.title}
                      </div>
                    </Link>
                  </div>
                </div>
              );
            });
          })()}
        </div>
      </div>
    </div>
  );
}

export default Home;
