import React from "react";
import Header from "./Header";

function Immunity() {
  return (
    <>
      <Header />
      <div className="App-detail-contents mx-auto">
        <h2 className="mb-5">免疫アップに</h2>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/immunity/person1.jpg`).default}
              />
            </div>
            <div className="mb-5">
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/immunity/item1-1.png`).default}
              />
            </div>
            <div>
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/immunity/item1-2.jpg`).default}
              />
            </div>
          </div>
          <div className="pl-5">
            <h4 className="mb-5">美素肌さぷり</h4>
            <p className="mb-5">
              年齢とともに疲れやすくなりエイジングを感じる身体の酸化、そして体に溜まった糖の反応で起きる糖化に着目し、美容業界で長年働いてきた経験を活かして、皆さんが安心して毎日続けられる、続けたくなる天然由来成分100％の健康サプリを開発。それが美素肌さぷりです。身体の中で水としっかり反応する水素化合物をつくる為に、沖縄県の厳しい管理の元、入手できる未焼成サンゴ末に、特許技術で水素を焼成（特許第4404657号）。そこにアンチエイジング研究所生まれの４種の植物から抽出された糖化ケアハーブ、UNAHATAKEハーブエキス®を添加しました。水素と、糖化ケアハーブが、体内で水と反応し、循環・還元します。活性酸素や、糖化最終生成物／Agesに働きかけ、体調を整え、取りすぎた糖や、溜まった糖の分解・抑制・排泄を促します。さらに詳しくは商品ページでご覧ください。
              さらに、ペットと過ごす時間を大切にされている愛犬家、愛猫家の方のためにプレミアムペット水素もご用意しました。
            </p>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/?category_id=5fbf2d63f0b108490012c80f"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Immunity;
