import React from "react";
import Header from "./Header";

function About() {
  return (
    <>
      <Header />
      <div className="App-detail-contents mx-auto">
        <h2 className="mb-5">『ふだんび』とは</h2>
        <section>
          <div className="mb-5">
            『ふだんびネット』の基本コンセプトは、「共に考え、共に行動しよう！」です。
            <br />
            <br />
            大切な人に大切な贈りものをするのにコンビニを利用する人は少ないですよね？
            巷に溢れるネットショップ、中には素晴らしい商品もあれば、そうでないものも。
            <br />
            本当に安心して使える生活用品、安心して食べられる食材は『ふだんび』でなら出会える！そんなネット
            ショップを作りたい！その思いで生まれたのが『ふだんびショップ』です。
            <br />
            <br />
            何年もかけて、数えきれない方の意見や切実な要望をお聴きして、この商品ならと厳選したときに共通
            していたこと。それは大量生産・大量消費をモットーにする企業が一社もいなかったこと。そして、生産者
            （あるいは共有者）全員が全精力を傾けて素晴らしいストーリーを語り、共感に値するメッセージを発して
            いることでした。
            <br />
            その結果生まれたコンセプトが「共に考え、共に行動しよう！」だったというわけです。
            運営者代表は、まもーれ合同会社ですが、『ふだんび』の実質的な運営者はここに参加するパートナー
            全員です。利害よりも理解を常に優先する人たち、プロダクトよりもコミュケーションを重視する人たち、
            そいういう人たちの思いを一人でも多くの方に知ってもらい、その価値を共有してくだされば幸いです。
            <br />
            <br />
            このネットワークは2020年12月1日が誕生日。これから健康に育っていくネットワークになるように、遠慮なく
            ご意見をお寄せください。
            <br />
            また、是非このネットワークに参加したいという方は自薦・他薦問いませんのでご連絡ください。
            <br />
            その時に絶対必要な情報は、ストーリーとメッセージです。どうぞよろしくお願い申し上げます。
          </div>
          <div className="text-right">
            fudanbi.net運営者代表：まもーれ合同会社高田裕子
          </div>
        </section>
      </div>
    </>
  );
}

export default About;
