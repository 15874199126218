import React from "react";
import Header from "./Header";

function Food() {
  return (
    <>
      <Header />
      <div className="App-detail-contents mx-auto">
        <h2 className="mb-5">健康な食生活</h2>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover w-full md:w-48"
                src={require(`../assets/images/food/person1.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-full md:w-48"
                src={require(`../assets/images/food/food1.jpg`).default}
              />
            </div>
          </div>
          <div className="pl-5">
            <h4 className="mb-5">スリム・スリランカカレー</h4>
            <p className="mb-5">
              1956年創業、インド・パキスタン料理専門店として多くの方にご支持いただいているデリーが普段美のために特別にご用意させていただきました。
              マスタードシード、カレーリーフ、スリランカ独特のローストカレーパウダーを、トマトと
              ココナッツミルクで煮込んだカレーです。ココナッツが苦手という方も大丈夫なように工夫。
              具は高温殺菌でも美味しさを保てるように、手羽中をコーンフラワーをまぶして揚げました。食感の変化にポテトも加え、スリランカカレーに欠かせないシナモンは一般にシナモンとして販売されているカシアではなく厳選したセイロンシナモンスティックを使用。
              常温保管で、賞味期限は製造より１年。たっぷりの250グラムアルミパウチ3個セットで
              お届けします。
              デリーならではのスペシャルでヘルシーなスリランカカレーを是非お試しください。
            </p>
            <div className="mb-5 text-right">
              （株）デリー　代表取締役社長　田中 源吾
            </div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/items/5fb0d63eb00aa3623284606b"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover w-full md:w-48"
                src={require(`../assets/images/food/person2.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-full md:w-48"
                src={require(`../assets/images/food/food2.jpg`).default}
              />
            </div>
          </div>
          <div className="pl-5">
            <h4 className="mb-5">米粉のシフォンケーキ モフモフ</h4>
            <p className="mb-5">
              東京・亀戸で米粉スイーツを作り続けているKome
              Co.(こめこ）がご用意したシフォンケーキ 8個セットです。
              私が米粉と出会い、最初に作ったのがシフォンケーキでした。シフォンケーキはちょっとパサパサしているという印象でしたが、米粉で作ったシフォンケーキはとってもしっとり、ふんわりしていて美味しいんですよ。当店のシフォンケーキは小麦粉は一切使っていないグルテン
              フリーですので、グルテンが体に合わない方は特に美味しく身体に優しい仕上がりになって
              います。
              冷凍でお届けしますので冷凍保存で賞味期限は2ヶ月、解凍後3日以内にお召し上がりください。
            </p>
            <div className="mb-5 text-right">
              米粉専門店 Kome Co. 代表　宇都宮 理恵
            </div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/items/5fa9297ef0b1085b62701789"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover w-full md:w-48"
                src={require(`../assets/images/food/person3.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-full md:w-48"
                src={require(`../assets/images/food/food3.jpg`).default}
              />
            </div>
          </div>
          <div className="pl-5">
            <h4 className="mb-5">成城ハニー・シナモン</h4>
            <p className="mb-5">
              世田谷で300年続く農場「宍戸園」12代目の宍戸健晃です。
              地域ブランドとなった非加熱処理のハチミツ「成城ハニー」とセイロンシナモンを独自配合
              した成城ハニー・シナモン2瓶に成城ハニー1瓶をつけた3個セットをお届けします。
              もともとブルーベリーなどのオーガニック農業で受粉率を上げるための蜂が増えたのがきっかけ。自然飼育で抗生物質などの薬を与えず加熱処理も施さないハチミツは美味しいですよ。
              セイロンシナモンは一般に売られているカシアと違ってクマリンをほとんど含有していないので肝臓障害を引き起こす心配もない。純粋なハチミツと純粋なシナモンを融合させると健康に良いことは昔から特にヨーロッパで知られています。血糖値を下げる作用で2型糖尿病に効果があり、尿路感染症や心疾患、抗炎症作用による関節炎の治療など実に多くの効果が期待できます。美味しい食習慣で皆さんの免疫力が向上し、健康な日常を送っていただければ農家としてこれ以上嬉しいことはありません。
            </p>
            <div className="mb-5 text-right">宍戸園　代表取締役　宍戸 健晃</div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/items/5fb2c358df51596fd869c69d"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Food;
