import React from "react";
import Header from "./Header";
import contactBtn from "../assets/images/contact/btn.png";
function Contact() {
  return (
    <>
      <Header />
      <div className="App-detail-contents mx-auto">
        <h2 className="mb-5">お問い合わせ</h2>
        <section>
          <div className="mb-5">
            『ふだんびネット』
            は、生活者である皆様、そして供給者である皆様からの貴重なご意見を最も
            大切にしています。
            <br />
            ショップに関するお問い合わせは、お手数ですが、ショップのお問い合わせフォーム
            <br />
            <a href="https://fudanbi.stores.jp/inquiry" target="__blank">
              https://fudanbi.stores.jp/inquiry
            </a>
            から、あるいは各商品の紹介ページにある。
          </div>
          <div className="mb-5">
            <img src={contactBtn} className="inline" width="250" />
            ボタンからお願いいたします。
          </div>
          <div className="mb-5">
            ここでは、『ふだんびネット』
            というネットワーク自体に関するお問い合わせ、ご意見、さらに
            <br />
            このネットワークに参加したい、あるいは、このネットワークで是非取り上げてもらいたい商品や
            <br />
            サービスに関しての提案をお待ちしております。
            <br />
            <br />
            あえてフォームは設けませんので、
            <br />
            <a href="mailto:info@mamo-re.com">info@mamo-re.com</a>
            宛に遠慮なくメールにてお問い合わせいただきたくお願い申し上げます。
          </div>
          <div className="text-right">
            fudanbi.net運営者代表：まもーれ合同会社高田裕子
          </div>
        </section>
        <a href="mailto:info@mamo-re.com"></a>
      </div>
    </>
  );
}

export default Contact;
