import React from "react";
import Header from "./Header";

function Company() {
  return (
    <>
      <Header />
      <div className="App-detail-contents mx-auto">
        <h2 className="mb-5">運営者情報</h2>
        <section>
          <div className="mb-5">
            『ふだんびネット』は、「自分の生活・自分の健康は自分自身でまもれ！」をコンセプトに2014年から活動を
            続けているまもーれ合同会社が運営するネットワークで、多くのビジネスパートナーの理解と協力のもとに、
            皆さんに安心して日々の生活に使っていただきたいアイテムをお届けする「ふだんびショップ」を営業しています。
            是非ご活用ください。
            <br />
          </div>
          <table>
            <tbody>
              <tr>
                <td className="w-24 border-gray-300 border p-3 bg-gray-100">
                  運営者
                </td>
                <td className="border-gray-300 border p-3">
                  まもーれ合同会社（Mamo-re LLC)
                </td>
              </tr>
              <tr>
                <td className="border-gray-300 border p-3 bg-gray-100">代表</td>
                <td className="border-gray-300 border p-3">高田裕子</td>
              </tr>
              <tr>
                <td className="border-gray-300 border p-3 bg-gray-100">住所</td>
                <td className="border-gray-300 border p-3">
                  〒276-0045
                  <br />
                  千葉県八千代市大和田280-27
                </td>
              </tr>
              <tr>
                <td className="border-gray-300 border p-3 bg-gray-100">
                  email
                </td>
                <td className="border-gray-300 border p-3">info@mamo-re.com</td>
              </tr>
              <tr>
                <td className="border-gray-300 border p-3 bg-gray-100">
                  サイト
                </td>
                <td className="border-gray-300 border p-3">
                  まもーれサイト：{" "}
                  <a href="https://www.mamo-re.com/" target="__blank">
                    https://www.mamo-re.com/
                  </a>
                  <br />
                  普段美ネットワークサイト：{" "}
                  <a href="https://www.fudanbi.net/" target="__blank">
                    https://www.fudanbi.net/
                  </a>
                  <br />
                  普段美ショップサイト：{" "}
                  <a href="https://fudanbi.stores.jp/" target="__blank">
                    https://fudanbi.stores.jp/
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </>
  );
}

export default Company;
