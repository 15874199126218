import React from "react";
import Header from "./Header";

function Livingware() {
  return (
    <>
      <Header />
      <div className="App-detail-contents mx-auto">
        <h2 className="mb-5">安心な生活用品</h2>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover w-full md:w-48 p-1"
                src={require(`../assets/images/livingware/person1.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-full md:w-48 p-1"
                src={require(`../assets/images/livingware/item1.jpg`).default}
              />
            </div>
          </div>
          <div className="md:pl-5">
            <h4 className="mb-5">バイオ消臭剤 OE-1</h4>
            <p className="mb-5">
              無害･無臭のバクテリアが腐敗臭を元から分解して消臭するバイオ消臭剤をお届けします。
              バクテリアは真正細菌とも呼ばれる微生物で、病気の原因になったり食べ物を腐らせたりしますが、私達の暮らしに役に立っていることも沢山あります。
              バクテリアなど生物の力を環境保全に利用する技術をバイオレメディエーションといい、これはバクテリアや菌、植物など生物とそれらの持つ酵素の化学物質分解能力を利用して、有害物質を無害な状態に戻し、汚染された地球環境保全を行う技術です。人がより良く生きるために小さな微生物の大きな力を借りて、私たちは環境浄化に取り組んでいます。バイオ消臭剤
              OE-1は、人やペットに全く害のないエアゾールタイプのバイオ製品で、散布する方や周りの人の健康を害する心配はありません。
              香りでごまかす消臭はもうやめましょう！
              230mlボトル2本、60mlミニボトル1本の3本セットでお届けしまので、ミニボトルは是非持ち歩いて
              外出先などでご活用ください。
            </p>
            <div className="mb-5 text-right">
              （有）バイオフューチャー代表　吉岡 克祥
            </div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/items/5fa9c248f0b1087083701c6b"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover w-full md:w-48"
                src={require(`../assets/images/livingware/person2.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-1/2 md:w-24 inline p-1"
                src={require(`../assets/images/livingware/item2-1.jpg`).default}
              />
              <img
                className="object-cover w-1/2 md:w-24 inline p-1"
                src={require(`../assets/images/livingware/item2-2.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-1/2 md:w-24 inline p-1"
                src={require(`../assets/images/livingware/item2-3.jpg`).default}
              />
              <img
                className="object-cover w-1/2 md:w-24 inline p-1"
                src={require(`../assets/images/livingware/item2-4.jpg`).default}
              />
            </div>
          </div>
          <div className="md:pl-5">
            <h4 className="mb-5">竹布セレクション</h4>
            <p className="mb-5">
              子供の頃から竹が好きで、竹から繊維ができないかと思ったのがきっかけ。2001年に竹から
              作った繊維を手に取った時に、陽の光に当たってキラキラと輝く繊維を見て「こんなに美しいものがみんなに受け入れられない訳がない」と思った。それから試行錯誤を経て最初にできた
              商品がボディータオル。竹布商品の原料は竹100％。とても柔らかい繊維となっても竹の特性
              はそのままに、優れた抗菌性・消臭性・制電性・吸湿吸水性・温熱効果があります。
              人が布製品に求める快適さ、機能性を生まれながらに備えているのが竹布（TAKEFU）です。
              普段美では、清布（ガーゼショール）、ボディタオル、癒布ワッチキャップ、ハラマキ、
              おやすみソックス、アーム＆レッグウォーマーを選ばせていただきました。その理由は個々の
              製品紹介でご覧ください。皆さんが竹布の素晴らしさを実感し、竹布のファンになってくださることを願っています。
            </p>
            <div className="mb-5 text-right">
              （株）ナファ生活研究所　代表取締役　相田 雅彦
            </div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/?category_id=5fbf2baedf51597923f4d8c6"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover  w-full md:w-48 p-1"
                src={
                  require(`../assets/images/livingware/person3-1.jpg`).default
                }
              />
            </div>
            <div className="mb-5">
              <img
                className="object-cover  w-full md:w-48 p-1"
                src={
                  require(`../assets/images/livingware/person3-2.jpg`).default
                }
              />
            </div>
            <div>
              <img
                className="object-cover  w-full md:w-48 p-1"
                src={require(`../assets/images/livingware/item3-1.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover  w-full md:w-48 p-1"
                src={require(`../assets/images/livingware/item3-2.jpg`).default}
              />
            </div>
          </div>
          <div className="md:pl-5">
            <h4 className="mb-5">弾性ストッキング</h4>
            <p className="mb-5">
              脚の形に基づいた6段階の立体設計でむくみ・疲れを、スッキリ美しくサポートします。一般的なストッキングに使用する糸より耐久性が高い、DCY（ダブルカバーリングヤーン）素材を、特殊機械を用いた編立技術で強弱多段階に編むことにより、透明感があり
              滑らかで優れたフィット感を実現しました。座る・立つ・歩くといった、動作に合わせて伸縮するマッサージ効果で、脚の疲れを和らげます。是非一度試して、実感してみてください。
            </p>
            <div className="mb-5 text-right">
              <div>(株）津田沼技研　メディカル開発部　今本 正雄</div>
              <div>代表取締役　末松 大司 </div>
            </div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/?category_id=5fbf2c59da019c7a008309df"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover  w-full md:w-48 p-1"
                src={require(`../assets/images/livingware/person4.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover  w-full md:w-48 p-1"
                src={require(`../assets/images/livingware/item4.jpg`).default}
              />
            </div>
          </div>
          <div className="md:pl-5">
            <h4 className="mb-5">ヘンプ100％の蚊帳</h4>
            <p className="mb-5">
              「日本の大麻を世界へ　世界の大麻を日本へ」をコンセプトに、浅草で麻製品専門店・麻草屋そして大麻と蚊帳の博物館を運営している松浦です。
              マリファナ？違いますよ、扱っているのは100％合法の大麻(ヘンプ)そして亜麻（リネン）を
              使った商品です。天然素材の麻は素晴らしい繊維を生み出します。普段美では100％竹から
              作った竹布の厳選商品を扱っていますよね。竹の繊維は素晴らしい、そして麻の繊維も素晴らしい。それぞれがさまざまなトラブルを抱えた現代社会に必要な素材だと思います。ですから
              それぞれのもつ特徴を活用したモノを選んでいただければ最高です。
              ここで皆さんにご紹介するのはヘンプの蚊帳。オーダーメイドの受注生産品になりますが、
              老若男女問わず、快適で健康的な空間、そして安眠をもたらすこと請け合いです。
              詳しくは商品ページを是非ご覧ください。
            </p>
            <div className="mb-5 text-right">浅草 麻草屋　代表　松浦 良樹</div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/items/5fbac8898a457241fcdb81cf"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
        <section className="flex mb-10 flex-col md:flex-row ">
          <div className="flex-none mb-5">
            <div>
              <img
                className="object-cover p-1 w-full md:w-48"
                src={require(`../assets/images/livingware/person5.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-1/2 h-56 md:w-24 md:h-32 inline p-1"
                src={require(`../assets/images/livingware/item5-1.jpg`).default}
              />
              <img
                className="object-cover w-1/2 h-56  w-16 md:w-24 md:h-32 inline p-1"
                src={require(`../assets/images/livingware/item5-2.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-1/2 h-56  w-16 md:w-24 md:h-32 inline p-1"
                src={require(`../assets/images/livingware/item5-3.jpg`).default}
              />
              <img
                className="object-cover w-1/2 h-56  w-16 md:w-24 md:h-32 inline p-1"
                src={require(`../assets/images/livingware/item5-4.jpg`).default}
              />
            </div>
          </div>
          <div className="p-1 md:pl-5">
            <h4 className="mb-5">究極のスプーン『葉風』</h4>
            <p className="mb-5">
              日本を代表する漆器の産地、福井県・鯖江市で約90年間伝統を守りつつ新しい商品開発を続けている山久漆工の山本です。
              <br />
              今まで手掛けた中で最も情熱を傾け、完成まで５年以上の歳月を要したのが今回ご紹介するスプーン。
              <br />
              「スプーンこそが木製漆塗りの魅力を体感できる最高のアイテムなのだから、手頃な値段でずっと愛用してもらえる最高品質の国産木製スプーンを作りたい！」という強い思いから実現した自信作です。
              <br />
              まず手に持ってください。まるでスプーンが自分の手の一部になったような持ちやすさを実感していただけるはずです。
              <br />
              次に料理を口に入れてみてください。金属製スプーンでは絶対に得られない食べやすさに驚かれると思います。
              <br />
              もう一つ大切なこと。それは漆のもつ強い抗菌性です。化学薬品や合成物質ではない
              100％天然素材、それが漆です。添加物や化学薬品処理ではなく、自然の漆を塗った漆器
              は、たいへん安全度の高い「自然の抗菌食器」抗菌試験検査では、大腸菌やMRSA（メチシ
              リン耐性黄色ブドウ球菌）も4時間後に半減。24時間後にゼロになるという強力な抗菌力
              をもっているのです。
              毎日口に運ぶものだからこそ安心してずっと使えるスプーンを是非ご愛用ください。
            </p>
            <div className="mb-5 text-right">
              山久漆工(株) 代表取締役山本泰三
            </div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/?category_id=5fe6d5b4df515918b6ecccb9"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Livingware;
