import React from "react";
import Header from "./Header";

function Body() {
  return (
    <>
      <Header />
      <div className="App-detail-contents mx-auto">
        <h2 className="mb-5">体のケア・学び</h2>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/body/person1.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/body/item1.jpg`).default}
              />
            </div>
          </div>
          <div className="pl-5">
            <h4 className="mb-5">音叉療法（ボディ・チューニング・メソッド）</h4>
            <p className="mb-5">
              あらゆる生命体は振動して生きています。
              1秒間当りの振動数を周波数と言い、人間や他の
              動物の体内にある全てのモノがそれぞれ固有の周波数をもっています。
              細胞はお互いに共鳴しあい、バランスを取り、体調を維持していますから、その周波数が何らかの理由でバランスを崩すと「不調＝病気」
              が起こると考えられています。
              音叉療法（ボディ・チューニング・メソッド）はそれぞれの振動周波数を整えて健康でキレイなカラダへと導きます。
              また病院で受けている治療の妨げにはならず
              病院の治療の替わりになるものでもないので
              その観点から「代替療法」ではなく「補完療法」と
              とらえていただければと思います。
              人体のエネルギーのアンバランスを解消し カラダの不調
              の改善を手助けしてくれる。それが音叉療法です。
            </p>
            <div className="mb-5 text-right">
              開発者・施術者　鍼灸師　高田 裕子
            </div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/items/5fbf6735f0b1082fb89233fe"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
            <h4 className="mb-5">世界最高品質の音叉</h4>
            <p className="mb-5">
              音叉療法のもう一つの大きな利点は、家庭でもできるということです。
              医療従事者や特別な知識がなくても、専門家のレクチャーを受けて理論とやり方を
              マスターでき、大切な家族や友人などのケアをしていただけます。
              音叉療法で非常に大切なことは高品質の音叉を使うこと。そのための音叉を解説付き
              で提供しています。　　　　　　　　　　　　　　　　　
            </p>
            <div className="mb-5 text-right">
              開発・製造者　(株）ニチオン・日本音叉研究所
            </div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/items/5fb0dd16f0b10857860a8640"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/body/person2.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/body/item2-1.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-1/2 md:w-24 inline p-1"
                src={require(`../assets/images/body/item2-2.jpg`).default}
              />
              <img
                className="object-cover w-1/2 md:w-24 inline p-1"
                src={require(`../assets/images/body/item2-3.jpg`).default}
              />
            </div>
            <div>
              <img
                className="object-cover w-1/2  md:w-24 inline p-1"
                src={require(`../assets/images/body/item2-4.jpg`).default}
              />
              <img
                className="object-cover w-1/2 md:w-24 inline p-1"
                src={require(`../assets/images/body/item2-5.jpg`).default}
              />
            </div>
          </div>
          <div className="pl-5">
            <h4 className="mb-5">電磁波対策グッズ</h4>
            <p className="mb-5">
              否応なく、これから携帯電話の通信の主流になるであろう５G.これは5ギガという意味ではなく、第五世代の通信システムという意味で、通信速度の高速化・多数同時接続などの利点が盛んに宣伝されています。このために従来の700Mhz～3.5GHzよりも幅の広い周波数（3.7GHz帯・4.5GHz帯）が使用されることになりますが、この新しい周波数帯がヒトの健康状態に影響を及ぼすかが世界中で議論の的になっています。
              従来の4Gでも電磁波過敏症で苦しんでいる方がいらっしゃいます。電磁波過敏症とは、携帯電話・電化製品・屋内配線・鉄塔などから漏れる電磁波で体調を崩すという、アレルギー疾患と似た症状です。皆さんが心電図・筋電図・脳波などを測定することからお分かりのように私たちの体の動きにも電気（生体電流）が使われています。それよりも大きな電気（生活の電磁波）が体に当たるために体が誤動作を起こしてしまうことが原因だと考えられています。症状としては、自律神経の乱れ（不眠・うつ・頭痛・吐き気・動悸・疲労）、皮膚障害（乾燥・赤身）などです。
              対策としては電磁波発生源を極力体から離すこと（携帯は特に頭部・胸部）ですが、限界がありますね。
              ここ普段美では、電磁波のノイズを減衰させることにより、体に有害な電磁波を有益な電磁波に変換するように対策グッズをセレクトさせていただきました。是非お試しください。
            </p>
            <div className="mb-5 text-right">
              開発者・医療法人社団 丸山アレルギークリニック 院長　丸山 修寛
            </div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/?category_id=5fbf2b2ada019c7a21831b51"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Body;
