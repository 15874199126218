import React from "react";

import "./App.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Home from "./Home";
import Food from "./Food";
import Livingware from "./Livingware";
import Cosmetic from "./Cosmetic";
import Immunity from "./Immunity";
import Body from "./Body";
import Company from "./Company";
import About from "./About";
import Contact from "./Contact";

function App() {
  return (
    <Router>
      <div className="App text-gray-800 overflow-hidden">
        <div>
          <Route exact path="/" component={Home} />
          <Route exact path="/food" component={Food} />
          <Route exact path="/livingware" component={Livingware} />
          <Route exact path="/cosmetic" component={Cosmetic} />
          <Route exact path="/immunity" component={Immunity} />
          <Route exact path="/body" component={Body} />
          <Route exact path="/about" component={About} />
          <Route exact path="/company" component={Company} />
          <Route exact path="/contact" component={Contact} />
        </div>
        <footer className="App-footer bg-white border-t px-5 pt-5 pb-10">
          <ul className="flex flex-col md:flex-row  justify-end divide-x divide-gray-100 mb-6">
            <li>
              <Link to="/about" className="flex p-3">
                <svg
                  className="icon mb-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                「ふだんび」とは
              </Link>
            </li>
            <li>
              <Link to="/company" className="flex p-3">
                <svg
                  className="icon mb-1 mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                運営者情報
              </Link>
            </li>
            <li>
              <Link to="/contact" className="flex p-3">
                <svg
                  className="icon mb-1 mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                お問い合わせ
              </Link>
            </li>
            <li>
              <a
                href="https://fudanbi.stores.jp/"
                target="__blank"
                className="flex p-3"
              >
                <svg
                  className="icon mb-1 mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                ふだんびショップ
              </a>
            </li>
          </ul>
          <div className="text-center">
            ©copyright: since 2020 Mamo-re LLC, all rights reserved.
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
