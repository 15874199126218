import React from "react";
import Header from "./Header";

function Cosmetic() {
  return (
    <>
      <Header />
      <div className="App-detail-contents mx-auto">
        <h2 className="mb-5">安心な化粧品</h2>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div className="mb-5">
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/cosmetic/person1.jpg`).default}
              />
            </div>
            <div className="mb-5">
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/cosmetic/item1.jpg`).default}
              />
            </div>
          </div>
          <div className="pl-5">
            <h4 className="mb-5">Mamo-Re バストチェック＆ケアクリーム</h4>
            <p className="mb-5">
              このバストクリームは私自身が自分の乳がんを克服した体験から作りました。
              乳がんにかかる人は食生活の変化と共に増え続け、毎年6万人以上と言われていますが、早期発見なら90%は治せるようになっています。
              乳がんを克服するための第一歩が早期発見。今までの“乳がんのセルフチェック”は月に一度石鹸などを使いシコリを見つけましょう！
              というような面倒な方法が多かったのです。でも、人は“楽しいこと”や“嬉しいこと”でないと続けられないですよね。だから、このクリームは天然ハーブティートリーの香りに包まれながら
              セルフチェックと同時にバストケアが楽しくできるように作りました。
              これからは 『自分のカラダは自分で守る』時代です。
              一人でも多くの方が笑顔になり キレイで元氣に輝けますように！！！
            </p>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/items/5fb0d915da019c663f19d576"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
        <section className="flex flex-col md:flex-row mb-10">
          <div className="flex-none mb-5">
            <div>
              <img
                className="object-cover  w-full md:w-48"
                src={require(`../assets/images/cosmetic/item2.jpg`).default}
              />
            </div>
          </div>
          <div className="pl-5">
            <h4 className="mb-5">Mamo-Re ネッククリーム</h4>
            <p className="mb-5">
              美容はり灸の現場から、美容面、健康面の両方をケアできるように開発したクリームです。どうしても人目が行き、年齢が隠せない所が「首元」そして現代はスマホが普及し「首こり」「首痛」「スマホ首」「ストレートネック」で悩む方が多くなりました。首とリンパの流れを良くすることで、表面的な美しさだけでなく、内側からの美しさを引き出します。
              東洋医学で伝わる「角質美肌ケア」の天然由来成分をマモーレオリジナルで配合。首イボ/首元の衰え/シワ/肌のゆるみ/黒ずみ/スマホ首/首こり/ストレートネック/あらゆる首元の悩みに対応するクリームです。　　　　
            </p>
            <div className="mb-5 text-right">開発者　鍼灸師　高田 裕子</div>
            <div className="mb-5 text-right">
              <a
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-3 rounded select-none w-full"
                href="https://fudanbi.stores.jp/items/5f5caf57fbe5b519e12de6a1"
                target="__blank"
              >
                さらに詳しく見る
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Cosmetic;
