import React from "react";
import { useState } from "react";
import { Transition } from "@headlessui/react";
import logo from "../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";

const categories = [
  { path: "/", title: "ホーム" },
  { path: "/food", title: "健康な食生活" },
  { path: "/livingware", title: "安心な生活用品" },
  { path: "/cosmetic", title: "安心な化粧品" },
  { path: "/immunity", title: "免疫アップに" },
  { path: "/body", title: "体のケア・学び" },
];

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  console.log(location);
  return (
    <header className="max-w-7xl mx-auto border-b">
      <div className="relative z-10 pb-8 bg-white">
        <svg
          className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>

        <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
          <nav
            className="relative flex items-center justify-between sm:h-10 lg:justify-start"
            aria-label="Global"
          >
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full lg:w-auto">
                <Link to="/">
                  <img className="App-logo w-64" src={logo} />
                </Link>
              </div>
            </div>
            <div className="hidden lg:block lg:ml-10 lg:pr-4 lg:space-x-8">
              {(() => {
                return categories.map((category) => {
                  if (location.pathname === category.path) {
                    return (
                      <span className="font-medium text-gray-900">
                        {category.title}
                      </span>
                    );
                  } else {
                    return (
                      <Link
                        to={category.path}
                        className="font-medium text-gray-500 hover:text-gray-900"
                      >
                        {category.title}
                      </Link>
                    );
                  }
                });
              })()}
            </div>
          </nav>
        </div>
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
          <div className="rounded-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <Link to="/">
                  <img className="App-logo w-64" src={logo} />
                </Link>
              </div>
              <div className="-mr-2">
                {(() => {
                  if (isOpen) {
                    return (
                      <button
                        type="button"
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <span className="sr-only">Close main menu</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    );
                  } else {
                    return (
                      <button
                        type="button"
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        id="main-menu"
                        aria-haspopup="true"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <span className="sr-only">Open main menu</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      </button>
                    );
                  }
                })()}
              </div>
            </div>
            <Transition
              show={isOpen}
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {(ref) => (
                <div
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="main-menu"
                >
                  <div
                    ref={ref}
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg"
                  >
                    <div className="rounded-md bg-white shadow-xs">
                      <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                        {(() => {
                          return categories.map((category) => {
                            if (location.pathname === category.path) {
                              return (
                                <span className="block px-3 py-2 rounded-md text-base font-medium text-gray-900">
                                  {category.title}
                                </span>
                              );
                            } else {
                              return (
                                <Link
                                  to={category.path}
                                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                >
                                  {category.title}
                                </Link>
                              );
                            }
                          });
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
